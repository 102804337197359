const AthleteRanking = {
  initialize() {
    this._onEverySelectChange()
    this._onRankingSlugSelectLoad()
    this._onRankingSlugSelectChange()
    this._onRankingCategorySelectLoad()
    this._onRankingCategorySelectChange()
    this._onWeightFilterChange()
    this._handleInputSearch()
  },
  _onWeightFilterChange() {
    $('#weight_filter').on('change', (event) => {
      $('#weight-division').val(event.currentTarget.value)
      $('#ranking-filters').trigger('submit')
    })
  },
  _onEverySelectChange() {
    // Clear athlete name search and weight filter.
    $('.ranking-filter').on('change', () => {
      $('#filters_search').val('')
      $('#weight_filter').val('')
      $('#weight-division').val('')
      this._handleInputSearch()
    })
  },
  _onRankingSlugSelectLoad() {
    $('.ranking-slug').each((_, el) => {
      this._handleFilters(el.value)
    })
  },
  _onRankingSlugSelectChange() {
    $('.ranking-slug').on('change', (event) => {
      const filter = event.currentTarget.value

      this._handleFilters(filter)
    })
  },
  _onRankingCategorySelectLoad() {
    $('.ranking-category').each((_, el) => {
      this._handleAgeDivisionSelect(el.value)
      this._showBeltSelect(el.value)
    })
  },
  _onRankingCategorySelectChange() {
    $('.ranking-category').on('change', (event) => {
      const category = event.currentTarget.value

      this._handleAgeDivisionSelect(category)
      this._showBeltSelect(category)
    })
  },
  _handleFilters(rankingFilter) {
    if (rankingFilter === 'ranking-geral-no-gi') {
      this._removeJuvenileOption()
      this._removeKidsOption()
      this._removeWhiteBeltOption()
    } else if (rankingFilter === 'ranking-geral-gi-cbjj') {
      this._removeJuvenileOption()
      this._removeKidsOption()
    } else {
      this._insertJuvenileOption()
      this._insertKidsOption()
      this._insertWhiteBeltOption()
    }

    this._handleWeightSelect(rankingFilter)
    this._handleInputSearch()
  },
  _removeOption(selector, optionValue) {
    if (this._optionExists(selector, optionValue))
      $(`select${selector} option[value=${optionValue}]`).remove()
  },
  _removeJuvenileOption() {
    this._removeOption('#filters_ranking_category', 'juvenile')
  },
  _removeKidsOption() {
    this._removeOption('#filters_ranking_category', 'kids')
  },
  _removeWhiteBeltOption() {
    this._removeOption('#filters_belt:visible', 'white')
  },
  _insertOption(selector, optionValue, optionText) {
    if (!this._optionExists(selector, optionValue))
      $(`select${selector} option`)
        .eq(-1)
        .after(`<option value="${optionValue}">${optionText}</option>`)
  },
  _insertJuvenileOption() {
    this._insertOption('#filters_ranking_category', 'juvenile', 'JUVENILE')
  },
  _insertKidsOption() {
    this._insertOption('#filters_ranking_category', 'kids', 'KIDS')
  },
  _insertWhiteBeltOption() {
    if ($('#filters_ranking_category').val() === 'kids') return
    if ($('#filters_s').val() === 'ranking-geral-no-gi') return

    this._insertOption('#filters_belt:visible', 'white', 'WHITE')
  },
  _optionExists(selector, optionValue) {
    return $(`select${selector} option[value=${optionValue}]`).length > 0
  },
  _handleAgeDivisionSelect(rankingCategory) {
    $('select.age-division').prop('disabled', true).hide()

    if (['juvenile', 'kids', 'master'].includes(rankingCategory)) {
      $(`select.age-division-${rankingCategory}`).prop('disabled', false).show()
    }

    $('select.age-division:hidden').val('')
  },
  _showBeltSelect(rankingCategory) {
    const beltCategory = ['kids', 'juvenile'].includes(rankingCategory)
      ? rankingCategory
      : 'adult'

    $('select.belt-filter').prop('disabled', true).hide()
    $(`select.belt-${beltCategory}`).prop('disabled', false).show()
    $('select.belt-filter:hidden').val('')
  },
  _handleWeightSelect(rankingFilter) {
    if (rankingFilter == 'kids') {
      this._removeOption('#weight_filter', 'openclass')
    } else {
      this._insertOption('#weight_filter', 'openclass', 'OPEN CLASS')
    }
  },
  _handleInputSearch() {
    const visibleSelects = $('select.ranking-filter:visible')
    let count = 0
    visibleSelects.each(function () {
      if ($(this).val() !== '') count++
    })

    if (visibleSelects.length == count) {
      $('#filters_search').prop('disabled', false).show()
    } else {
      $('#filters_search').prop('disabled', true).hide()
    }

    if (count == 4) {
      $('#second-row-column').addClass('filters-four-columns')
    } else {
      $('#second-row-column').removeClass('filters-four-columns')
    }
  },
}

export default AthleteRanking
